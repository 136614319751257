import { ACTIONS } from "../constants";
const initialState = {
  children: [],
  vaccinations: [],
  vaccinations_by_category: [
    { category: 1, totalSize: 0, vaccination: [] },
    { category: 2, totalSize: 0, vaccination: [] },
    { category: 3, totalSize: 0, vaccination: [] },
    { category: 4, totalSize: 0, vaccination: [] },
    { category: 5, totalSize: 0, vaccination: [] },
  ],
  hasParents: true,
  child: {},
  mapiList: [],
  allergyList: [],
  growthList: [],
  graph: {
    data: [],
    label: [],
  },
  filterType: {
    type: "",
    unit: "",
  },
  growthItem: {},
  headCircumferenceList: [],
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  numEvax:null,
  success: {
    create: false,
    otp: false,
    edit: false,
    fetch: false,
    delete: false,
  },
  error: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_CHILDREN: {
      return {
        ...state,
        success: { create: false, edit: false, fetch: false, delete: false, otp: false },
        error: null,
      };
    }
    case ACTIONS.CLEAR_CHILDREN_1: {
      return {
        ...state,
        success: {  edit: false, fetch: false, delete: false, otp: false , ...state.success},
        error: null,
      };
    }

    case ACTIONS.CLEAR_CHILD: {
      return {
        ...state,
        success: { create: false, edit: false, fetch: false, delete: false },
        error: null,
        child: {}
      };
    }

    case ACTIONS.ALLERGY_LIST_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.ALLERGY_LIST_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: false,
        allergyList: payload,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.ALLERGY_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.CHECK_PARENT_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.CHECK_PARENT_SUCCEDED: {
      // console.log(payload)
      let checked = payload.checkParents == 1 ? false : true;
      return {
        ...state,
        isLoading: false,
        hasParents: checked,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CHECK_PARENT_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_CHILDRENLIST_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_CHILDRENLIST_SUCCEDED: {
      return {
        ...state,
        children: payload,
        isFetching: false,
        error: null,
        success: { fetch: true },
      };
    }
    case ACTIONS.FETCH_CHILDRENLIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_CHILD_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_CHILD_SUCCEDED: {
      return {
        ...state,
        child: payload,
        isFetching: false,
        error: null,
        success: { fetch: true },
      };
    }
    case ACTIONS.FETCH_CHILD_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.EDIT_CHILD_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.EDIT_CHILD_SUCCEDED: {
      return {
        ...state,
        isLoading: false,
        child: payload,
        children: state.children.map((child) =>
          child.id === payload.id ? payload : child
        ),
        isLoading: false,
        error: null,
        success: { ...state.success, edit: true },
      };
    }
    case ACTIONS.EDIT_CHILD_FAILED: {
      return { ...state, isFetching: false,isLoading:false, error: payload };
    }

    case ACTIONS.CHANGE_PHONE_PROFILE_OTP_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.CHANGE_PHONE_PROFILE_OTP_SUCCEDED: {
      return {
        ...state,
        isLoading: false,
        error: null,
        success: { ...state.success, otp: true },
      };
    }
    case ACTIONS.CHANGE_PHONE_PROFILE_OTP_FAILED: {
      return { ...state, isFetching: false,isLoading:false, error: payload };
    }

    case ACTIONS.VERIF_PHONE_PROFILE_UPDATE_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.VERIF_PHONE_PROFILE_UPDATE_SUCCEDED: {
      return {
        ...state,
        isLoading: false,
        child: payload,
        children: state.children.map((child) =>
          child.id === payload.id ? payload : child
        ),
        isLoading: false,
        error: null,
        success: { ...state.success, edit: true },
      };
    }
    case ACTIONS.VERIF_PHONE_PROFILE_UPDATE_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.DELETE_CHILD_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.DELETE_CHILD_SUCCEDED: {
      console.log(payload);
      return {
        ...state,
        children: state.children.filter((item) => payload.id !== item.id),
        isLoading: false,
        error: null,
        success: { ...state.success, delete: true },
      };
    }
    case ACTIONS.DELETE_CHILD_FAILED: {
      return { ...state, isLoading: false, error: null };
    }

    case ACTIONS.CREATE_CHILD_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.CREATE_CHILD_SUCCEDED: {
      const { id: param } = payload;
      return {
        ...state,
        child: { param },
        children: state.children.concat(payload),
        isLoading: false,
        error: null,
        numEvax:payload.numEvax,
        success: { ...state.success, create: true },
      };
    }
    case ACTIONS.CREATE_CHILD_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }
    case ACTIONS.CREATE_STRANGER_CHILD_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.CREATE_STRANGER_CHILD_SUCCEDED: {
      const { id: param } = payload;
      return {
        ...state,
        child: { param },
        children: state.children.concat(payload),
        isLoading: false,
        numEvax:payload.numEvax,
        error: null,
        success: { ...state.success, create: true },
      };
    }
    case ACTIONS.CREATE_STRANGER_CHILD_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.FETCH_VACCINATION_LIST_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_VACCINATION_LIST_SUCCEDED: {
      const { results, count } = payload
      return {
        ...state,
        vaccinations: results ? results : payload,
        totalSize: count,
        isFetching: false,
        error: null,
        success: { ...state.success, fetch: true },
      };
    }
    case ACTIONS.FETCH_VACCINATION_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_GROWTH_LIST_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_GROWTH_LIST_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        growthList: payload,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_GROWTH_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_HEAD_CIRCUMFERENCE_LIST_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_HEAD_CIRCUMFERENCE_LIST_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        headCircumferenceList: payload,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_HEAD_CIRCUMFERENCE_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.COURBE_GROWTH_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.COURBE_GROWTH_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        graph: { data: payload, label: payload },
        payload,
        graphData: {
          data: payload.map((item) => item.weight),
          label: payload.map((item) => item.height),
        },
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.COURBE_GROWTH_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FILTER_COURBE: {
      return {
        ...state,
        filterType: { type: payload?.type, unit: payload?.unit },
      };
    }

    case ACTIONS.ADD_SECOND_PARENT_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.ADD_SECOND_PARENT_SUCCEDED: {
      return {
        ...state,
        isLoading: false,
        error: null,
        success: { ...state.success, create: true },
        hasParents: true,
      };
    }
    case ACTIONS.ADD_SECOND_PARENT_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.COURBE_YEAR_MONTH: {
      return { ...state, filterType: { ...state.filterType, unit: payload } };
    }

    case ACTIONS.LINK_CHILD_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.LINK_CHILD_SUCCEDED: {
      const { id: param } = payload;
      return {
        ...state,
        child: { param },
        children: state.children.concat(payload),
        isLoading: false,
        error: null,
        success: { ...state.success, create: true },
      };
    }
    case ACTIONS.LINK_CHILD_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }
    case ACTIONS.LINK_STRANGER_CHILD_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.LINK_STRANGER_CHILD_SUCCEDED: {
      const { id: param } = payload;
      return {
        ...state,
        child: { param },
        children: state.children.concat(payload),
        isLoading: false,
        error: null,
        success: { ...state.success, create: true },
      };
    }
    case ACTIONS.LINK_STRANGER_CHILD_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.FETCH_VACCINATION_LIST_BY_CATEORY_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_VACCINATION_LIST_BY_CATEORY_SUCCEDED: {
      const { results, count } = payload
      const updatedList = state.vaccinations_by_category.map(vac => {
        if (vac.category === results[0].category) {
          if (results.length !== 0) {

            const category = {}
            category.category = vac.category
            category.totalSize = count
            category.vaccination = results
            return category
          }
          else {
            const category = {}
            category.category = vac.category
            category.totalSize = vac.count ? vac.count : 0
            category.vaccination = category.vaccination ? category.vaccination : []
            return category
          }
        } else {
          return vac
        }
      })
      return {
        ...state,
        vaccinations_by_category: updatedList,
        isFetching: false,
        error: null,
        success: { ...state.success, fetch: true },
      };
  }
    case ACTIONS.FETCH_VACCINATION_LIST_BY_CATEORY_FAILED: {
    return { ...state, isFetching: false, error: payload };
  }

    case ACTIONS.FETCH_MAPI_LIST_INIT: {
    return { ...state, isFetching: true, error: null };

  }
    case ACTIONS.FETCH_MAPI_LIST_SUCCEDED: {
    const { count, results } = payload
    return {
      ...state,
      isLoading: false,
      isFetching: false,
      mapiList: results,
      totalSize: count,
      error: null,
      success: initialState.success,
    };
  }
    case ACTIONS.FETCH_MAPI_LIST_FAILED: {
    return { ...state, isFetching: false, error: payload };
  }


    default: {
    return state;
  }
}
};
